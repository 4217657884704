<template>
  <VideoUploaderInput
    :file.sync="file"
    :placeholder="$t('message.selectVideo')"
    :loading="loading"
    :percentUploaded="percentUploaded"
  />
</template>

<script>
import * as Sentry from '@sentry/browser'
import VideoUploaderInput from './video-uploader-input'
import { firebase } from '@/helpers/firebase'
import { VIDEO_STORAGE_KEY } from '@/enums'
import { mapState } from 'vuex'

export default {
  components: {
    VideoUploaderInput
  },
  i18n: { // `i18n` option, setup locale info for component
    messages: {
      en: { message: {
        selectVideo: 'Please select a video'
      } },
      ja: { message: {
        selectVideo: 'ビデオを選んでください'
      } }
    }
  },
  data: () => ({ file: null, loading: false, percentUploaded: 0 }),
  props: {
    input: {
      type: Object,
      required: false
    },
    sendInput: {
      type: Function,
      required: false
    }
  },
  computed: {
    ...mapState('auth', ['uid'])
  },
  watch: {
    file (file) {
      if (file) {
        this.uploadVideo({ file })
      }
    }
  },
  methods: {
    async uploadVideo ({ file }) {
      try {
        this.loading = true
        const storageRef = firebase.storage().ref().child(`${VIDEO_STORAGE_KEY}/${this.uid}`)
        const uploadTask = storageRef.put(file)
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            this.loading = true
            const percent = Math.ceil(snapshot.bytesTransferred / snapshot.totalBytes * 100)
            this.percentUploaded = percent
          },
          error => {
            Sentry.captureException(error)
            /* eslint-disable-next-line */
            console.error(error)
          },
          async () => {
            const userImageUrl = await uploadTask.snapshot.ref.getDownloadURL()
            this.percentUploaded = 100
            this.sendInput(userImageUrl)
          }
        )
      } catch (err) {
        // eslint-disable-next-line
        console.error("Error uploading applicant video2: ", err);
        Sentry.captureException(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
</style>
