<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <slot name="activator" :on="{click: launchFilePicker}" />
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input ref="file" type="file" style="display:none" @change="onFileChange" :accept="accept" />
  </div>
</template>

<script>
export default {
  name: 'ImageInput',
  props: {
    accept: {
      type: String,
      default: null
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.file.click()
    },
    onFileChange (e) {
      const file = event.target.files[0] || null
      this.$emit('input', file)
    }
  }
}
</script>
