<template>
  <FileInput v-if="!loading" v-model="file" accept="video/*">
    <template v-slot:activator="{on}">
      <v-text-field
        v-on="on"
        append-outer-icon="mdi-send"
        @click:append-outer="onSubmit"
        @keydown.enter="onUserEnter"
        :hide-details="true"
        solo
        :disabled="loading"
        prepend-icon="mdi-file-video"
        :placeholder="placeholder"
        readonly
        :value="fileName"
        clearable
        @click:clear="file = null"
      />
    </template>
  </FileInput>
  <v-row align="center" v-else class="loading-video mx-2">
    <v-col cols="auto">
      <v-icon>mdi-file-video</v-icon>
    </v-col>
    <v-col>
      <v-progress-linear height="25" :value="percentUploaded">
        <template v-slot="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>

import FileInput from '@/components/shared/file-input'
export default {
  components: {
    FileInput
  },
  props: {
    placeholder: {
      type: String,
      default: 'ビデオを選んでください'
    },
    loading: {
      type: Boolean,
      default: false
    },
    percentUploaded: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      file: null,
      uploaded: false
    }
  },
  computed: {
    fileName () {
      return this.file ? this.file.name : ''
    }
  },
  methods: {
    onSubmit () {
      if (this.file) {
        this.$emit('update:file', this.file)
      }
    },
    onUserEnter (event) {
      if (event.metaKey || event.ctrlKey) {
        this.onSubmit()
      }
    }
  }
}
</script>

<style scoped>
.loading-video {
  height: 48px;
}
</style>
